<template>
    <div v-if="selfServiceDetails.tag ==='end'">
            <p>{{$t('pages.selfservice.userActivation.activationSuccessMessage')}}</p>
    </div>
    <div v-else>
        <p class='h2 mb-4'>
            {{$t('pages.selfservice.userActivation.title')}}
        </p>
        <p class='text-center mb-4'>
            {{$t(`pages.selfservice.userActivation.activateInstruction`)}}
        </p>
        <b-form @keyup.enter="save" @submit.prevent>
            <b-button @click="save" size="lg" :block="true" variant="primary">
                {{$t("pages.selfservice.userActivation.advanceStageButtonText")}}
            </b-button>
        </b-form>
    </div>
</template>

<script>
import _ from 'lodash';

export default {
    name: 'User-Activation',
    props: {
        selfServiceDetails: { required: true }
    },
    data () {
        let saveData = {},
            displayDetails = [];

        if (this.selfServiceDetails && this.selfServiceDetails.requirements && this.selfServiceDetails.requirements.properties) {
            _.each(this.selfServiceDetails.requirements.properties, (prop, key) => {
                displayDetails.push({
                    type: prop.type,
                    text: prop.description,
                    key: key
                });

                if (prop.type === 'string') {
                    saveData[key] = '';
                } else if (prop.type === 'boolean') {
                    saveData[key] = false;
                } else {
                    saveData[key] = null;
                }
            });
        }

        return {
            saveFields: saveData,
            displayDetails: displayDetails
        };
    },
    methods: {
        getData () {
            return this.saveFields;
        },

        save () {
            this.$emit('advanceStage', this.getData());
        },

        isValid () {
            return Promise.resolve(true);
        }
    },
    filters: {
        startCase (value) {
            return _.startCase(value);
        }
    }
};
</script>

<style scoped></style>
